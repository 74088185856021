import React, {useEffect, useState, useRef} from 'react'
// import propTypes from 'prop-types'
// import jaycoClient from '../../../graphql/stockClient';
import repaymentCalc from '../../../util/repaymentCalc';
import {get} from 'lodash';
import { Grid, Box } from '@material-ui/core';
// import {gql} from 'apollo-boost';
import axios from 'axios';
import toPrice from '../../../util/toPrice';
import StockCard from '../../../components/navigation/StockCard';
import { theme } from '../../../themes/default-theme';
import Slider from '../SsrSlider';
import { ReactSVG } from 'react-svg';
import chevronLeft from '../../../assets/icons/chevron-left.svg';
import chevronRight from '../../../assets/icons/chevron-right.svg';
import NextLink from '../../navigation/NextLink'
import Cta from '../../ui/Cta'
import styled from 'styled-components';
import Text from '../../typography';


const Container = styled.div`
    background-color: ${theme.palette.lightGrey};
    padding: 72px 0;

    ${theme.breakpoints.down('md')} {
        padding: 60px 0;
    }

    .slick-track {
        display: flex !important;
    }

    .slick-slide {
        height: inherit !important;

        > div {
            ${theme.breakpoints.up('lg')} {
                height: 100%;
            }
        }
    }

    .slick-dots {
        list-style: none;
        text-align: center;
        font-size: 0;
        text-align: center;
        padding: 0;
        margin-top: 25px;

        li {
            display: inline-block;
            padding: 0 6px;

            &.slick-active {
                button {
                    background-color: ${theme.palette.vividBlue};
                }
            }

            &.slick-next,
            &.slick-prev {
                ${theme.breakpoints.down('md')} {
                    display: none;
                }
                button {
                    background-color: transparent;
                    padding: 0;
                }

                svg {
                    height: 10px;
                    width: auto;
                    position: relative;
                    top: 5px;
                    [stroke] {
                        stroke: #758094;
                    }
                }
            }

            svg {
                ${theme.breakpoints.down('md')} {
                    display: none;
                }
            }
        }

        button {
            cursor: pointer;
            width: 12px;
            height: 12px;
            border-radius: 12px;
            border: none;
            background-color: ${theme.palette.middleGrey};
            font-size: 0;
        }
    }

    ${props => props.carouselLength < 5 &&
        `
        ${theme.breakpoints.up('lg')} {
            .slick-track {
                text-align: center;
            }
    
            .slick-slide {
                width: 25% !important;
                display: inline-block;
                float: none;
            }    
        }

        `
    }
`
const Heading = styled(props => {
    return <Text {...props} />
})`
    margin-bottom: 35px;
    text-align: center;
    ${theme.breakpoints.up('lg')} {
        margin-bottom: 60px;
    }
`

const Showing = styled(Text)`
    margin-bottom: 35px;
    text-align: center;

    ${theme.breakpoints.up('lg')} {
        margin-bottom: 60px;
    }
`

const SliderContainer = styled.div`
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;


`

const StockCardItem = styled.div`

    padding: 0 8px;
    margin-bottom: 15px;
    ${theme.breakpoints.up('lg')} {
        height: 100%;
    }

    > div {
        margin-left: auto;
        margin-right: auto;
      
        ${theme.breakpoints.up('lg')} {
            height: 100%;
        }


        ${theme.breakpoints.up('md')} {
            max-width: 320px;
        }
    }
`

const CtasContainer = styled.div`
    font-size: 0;
    margin-top: 26px;
    text-align: center;
`
const CtaItem = styled.div`
    display: inline-block;
`

const AvailableStock = (props) => {

    const [stock, setStock] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const slider = useRef();

    const getYear = (date) => {
        const s = date.toString();
        return s.substr(s.length - 4);
    }

    const refactorUbsData = (data) => {
        return data.map((item, index) => {
          const images = item.images ? item.images.split(',') : [];
    
          const price = (item.driveAwayPrice !== undefined || item.driveAwayPrice !== null) ? item.driveAwayPrice : item.askingPrice
    
          return {
            ...item,
            brand: item.make || null,
            category: item.variant,
            year: getYear(item.buildDate),
            id: item.id,
            type: item.type,
            name: item.model,
            price: price ? price : 0,
            images,
            weeklyRepayment: (price && price.toString() !== '0' && props.rates) ? parseInt(repaymentCalc(props.rates, 52, price)) : null,
            cta: {
              text: "Find out more",
              url: `/for-sale/all?model=${item.id}`
            }
          }
        })
    }

    const getLsItem = (value) => {
        const json = localStorage.getItem(value);
        return json ? JSON.parse(json): null;
    }

    const getUserLocation = (stock) => {
        // Try to get state from dealer name
        const dealer = getLsItem('dealer');
        
        console.log('getUserLocation dealer1', dealer);

        if (dealer && dealer.title) {
            const matchedDealer = stock.find(item => item.dealerName.toLowerCase() === dealer.title.toLowerCase());
            console.log('getUserLocation dealer2', matchedDealer);

            if (matchedDealer) {
                return {
                    state: matchedDealer.state,
                    dealer: matchedDealer.dealerName
                }
            }
        }

        // Then try to get from userLocation & postcodes in local storage 
        const userLocation = getLsItem('userLocation');
        const postcodes = getLsItem('postcodes');

        if (userLocation && userLocation.postcode && postcodes && postcodes.data) {
            console.log('userLocation and postcodes', {userLocation, postcodes});
            const matchedPostcode = postcodes.data.find(item => {
                return item.postcode.toString() === userLocation.postcode.toString();
            })

            if (matchedPostcode && matchedPostcode.state_code) {
                return {
                    state: matchedPostcode.state_code
                }
            }
        }

        // Else return nothing
        return;
    }

    useEffect(() => {
    
        const windowDealerId = process.env.DEALER_SITE && window.staticly.dealerData.id.indexOf('{{') === -1 ? window.staticly.dealerData.id : null
        const queryDealerId = windowDealerId ? windowDealerId : get(props, 'router.query.dealerid', null);
        const dealerId = queryDealerId ? queryDealerId : props.dealerId;

        console.log('dealerId', {windowDealerId, queryDealerId, dealerId, props});

        const getAllData = async (dealerId) => {

            await axios({
              method: 'GET',
              url: 'https://www.jayco.com.au/feeds/dealer-stock'
            })
            .then((response) => {
              console.log('response!', response);
              let stock = [];

              if (dealerId) {
                const dealerStock = response.data.dealers.find(item => item.id.toString() === dealerId.toString());
                if (dealerStock) {
                    stock = dealerStock.stock
                }
                console.log('dealerStock', {dealerStock, stock, dealers: response.data.dealers});

              } else {
                response.data.dealers.forEach(item => {
                    stock.push(...item.stock.map(stockItem => {
                        return {
                            ...stockItem,
                            dealerId: item.id,
                            dealerName: item.name, 
                            state: item.state,
                        }
                    }))
                })
              }

              const filteredStock = stock.filter(item => {
                
                if (props.filters) {
                    if (props.filters.type && item.type && props.filters.type.toLowerCase() !== 'all' && props.filters.type.toLowerCase() !== item.type.toLowerCase()) {
                        return false
                    }
                    
                    if (props.filters.minPrice && parseInt(props.filters.minPrice) > parseInt(item.driveAwayPrice)) {
                        return false
                    }
                    
                    if (props.filters.maxPrice && parseInt(props.filters.maxPrice) <= parseInt(item.driveAwayPrice)) {
                        return false
                    }

                    if (props.filters.category && item.variant && props.filters.category.toLowerCase() !== 'all' && (props.filters.category.toLowerCase() !== item.variant.toLowerCase())) {
                        return false
                    }
                    return true
                }

                return true;
              })

              const userLocation = dealerId ? null : getUserLocation(filteredStock);

              let sortedStock = []

              if (userLocation && !dealerId) {
                let allStock = [...filteredStock];
                let dealerStock = [];
                let stateStock = [];

                if (userLocation.dealer) {
                    allStock.filter(item => {
                        if (item.dealerName.toLowerCase() === userLocation.dealer.toLowerCase()) {
                            dealerStock.push(item);
                            return false;
                        }
                    })
                }

                if (userLocation.state) {
                    allStock.filter(item => {
                        if (item.state.toLowerCase() === userLocation.state.toLowerCase()) {
                            stateStock.push(item);
                            return false;
                        }
                    })
                }

                sortedStock = [...dealerStock, ...stateStock, ...allStock];

              } else {
                sortedStock = (filteredStock);
              }
              
              setStock(refactorUbsData(sortedStock));

              setLoading(false)
           
            })
            .catch((error) => {
              console.log('Request error: ', error);
              setLoading(false)
              //setApiData(testData);
            });
        }
        
        getAllData(dealerId)

        // if (dealerId) {
        //     getDealerData(dealerId);
        // } else {
        //     getAllData(dealerId)
        // }
    
    }, []);

    console.log('filtered stock and props', stock, props);

    const handleArrowClick = (type) => {
        if (type === 'prev') {
            slider.current.slickPrev();
        } else {
            slider.current.slickNext();
        }
    }

    const stockLength = stock ? stock.length : 0

    const slickSettings = {
        fade: false,
        dots: true,
        rows: 1,
        slidesToShow: stockLength < 4 ? stockLength : 4,
        arrows: false,
        slidesToScroll: 1,
        infinite: false,
        appendDots: (dots) => {
            return (
                <ul className="slick-dots">
                    <li className="slick-arrow slick-prev"><button onClick={() => handleArrowClick('prev')}><ReactSVG src={chevronLeft} /></button></li>
                    {dots}
                    <li className="slick-arrow slick-next"><button onClick={() => handleArrowClick('next')}><ReactSVG src={chevronRight} /></button></li>
                </ul>
            )
        },
        responsive: [
            {
                breakpoint: theme.breakpoints.width('sm'),
                settings: {
                    slidesToShow: stockLength > 1 ? 1 : 1,
                    rows: stockLength > 4 ? 4 : 2
                }
            },
            {
                breakpoint: theme.breakpoints.width('md'),
                settings: {
                    slidesToShow: stockLength > 1 ? 2 : 1,
                    rows: stockLength > 4 ? 2 : 1
                }
            }
        ]
    }

    const title = props.heading ? props.heading : `${stockLength} models now in stock`

    return (!loading && stockLength > 0) ? (
    	<Container carouselLength={stockLength}>
            <Box px={2}>
                <Heading variant="h3" element={props.hTag ? props.hTag : 'h3'} size="extraLarge" colour="charcoal" uppercase={true} weight={800}>{title}</Heading>

                {/* <Showing variant="body1" align="center">
                    Showing {stockLength < 4 ? `${stockLength}` : '4' } of {stockLength}
                </Showing> */}

                <SliderContainer>
                    <Slider
                        ref={slider}
                        settings={slickSettings}
                        arrowProps={{
                            grey: false,
                            lightGrey: true,
                            darkGrey: false,
                            medium: true
                        }}
                    >
                        {stock && stock.slice(0, 12).map(item => (
                            <StockCardItem key={item.id || index}>
                                <StockCard
                                    {...item}
                                    price={item.price ? `$${toPrice(item.price)}` : null}
                                />
                            </StockCardItem>
                        
                        ))}
                    </Slider>
                </SliderContainer>
                {(props.cta && props.cta.href && props.cta.href.length > 0 && props.cta.title && props.cta.title.length > 0) ?
                    <CtasContainer>
                        <CtaItem>
                            <NextLink href={props.cta.href}>
                                <a>
                                    <Cta 
                                        inline 
                                        primary={props.cta.style === 'primary'}
                                        secondary={props.cta.style === 'secondary'}
                                        outline={props.cta.style === 'outline'}
                                    >
                                        {props.cta.title}
                                    </Cta>
                                </a>
                            </NextLink>
                        </CtaItem>
                    </CtasContainer>
                    : null
                }
            </Box>
        </Container>
    ) : null
}
    
AvailableStock.propTypes = {
}
    
AvailableStock.defaultProps = {
}
    
export default AvailableStock