import payment from './pmt';

const repaymentCalc = (rates, paymentFrequency, price) => {

  const deposit = 0;
  const term = rates.term;
  const rate = rates.rate

  const establishmentFee = rates.establishmentFee
  const monthlyFee = rates.monthlyFee || 8.90;
  const onGoingFee = (monthlyFee * 12) / paymentFrequency;
  const loanAmount = price + establishmentFee - (deposit.toString().trim().length === 0 ? 0 : parseFloat(deposit));
  const interestRate = parseFloat(((rate/100) / paymentFrequency));
  const periods = term * paymentFrequency;
  const pmt = payment(interestRate, periods, loanAmount, 0, 1)
  const pmtWithFee = pmt - onGoingFee;
  return Math.abs(pmtWithFee)
}

export default repaymentCalc